import React from 'react';
import { Link } from 'gatsby';
import Loadable from '@loadable/component';
// Style Imports
import './styles.scss';
// Content imports
import Image from '@components/content/fractals-image.jsx';
import Hero from '@components/content/hero/Hero.Homepage.jsx';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
// Utility imports
import Seo from '@components/utility/SEO';
// Content imports
const IconMailLarge = Loadable(() => import('@components/content/icons/MailLarge.jsx'));
const Cta = Loadable(() => import('@components/content/cta/CTA'));

const Index = () => (
	<Layout addedClass='page--home'>
		<Seo
			title='An Option for Lupus Nephritis | LUPKYNIS&reg; (voclosporin)'
			description='LUPKYNIS is a prescription medicine used with other medicines to treat adults with active lupus nephritis. See how LUPKYNIS works and sign up for the latest news. See full Safety and Prescribing Information, and BOXED WARNING.'
			canonicalURL='https://www.lupkynis.com/'
		/>
		<Hero />
		<section id='LearnMore' className='bg-light-green'>
			<div className='rounded-banner text-center'>
				<div className='container'>
					<p>
						97% of the time*, patients paid less than $10 for their LUPKYNIS
						prescription.
					</p>
					<p>
						Sign up <Link to='/registration'>here</Link> to get started.
					</p>
					<p className='disclaimer'>
						*97% of the shipments of LUPKYNIS delivered cost the patient $10
						or less. These are patients on commercial insurance, Medicaid or
						Medicare from January 2022 to December 2022.
					</p>
				</div>
			</div>
			<div className='container'>
				<div className='text-center copy-block'>
					<h2>
						LUPKYNIS is the{' '}
						<span className='ripped-tape'>
							1<sup>st</sup> FDA-approved
						</span>{' '}
						oral treatment <span>specifically</span> for lupus nephritis
					</h2>
				</div>
				<div className='grid-wrapper'>
					<div className='grid'>
						<div className='grid__row'>
							<div className='grid__item'>
								<Image
									data={{
										desktopSrc: 'home/appt-sticky.png',
										mobileSrc: 'home/appt-sticky--mobile.png',
									}}
								/>
							</div>
							<div className='grid__item'>
								<div className='grid__copy'>
									<p>
										LUPKYNIS works by helping to stop the attack of
										lupus nephritis, while protecting the kidneys from
										irreversible damage caused by this serious
										disease.
									</p>
									<p>
										In clinical trials, LUPKYNIS helped control lupus
										nephritis with low-dose steroids.
									</p>
									<Cta
										url='/taking-lupkynis#WatchVideo'
										isCircleArrow={true}>
										See how LUPKYNIS is helping people
									</Cta>
								</div>
							</div>
						</div>
						<div className='grid__row'>
							<div className='grid__item'>
								<Image
									data={{
										desktopSrc: 'home/hug-sticky.png',
										mobileSrc: 'home/hug-sticky--mobile.png',
										altText:
											'About 1 out of 2 people living with lupus may go on to develop lupus nephritis',
									}}
								/>
							</div>
							<div className='grid__item'>
								<div className='grid__copy'>
									<h3 className='heading heading--subhead'>
										Lupus nephritis is an attack on the kidneys caused
										by lupus
									</h3>
									<p>
										<strong>
											Lupus nephritis is a common and serious
											complication that about 1 out of 2 people
											living with lupus may go on to develop.
										</strong>{' '}
										If left untreated, lupus nephritis often leads to
										serious health problems, permanent kidney damage,
										and even kidney failure.
									</p>
									<Cta
										url='/what-is-lupus-nephritis'
										isCircleArrow={true}>
										Find out more about lupus nephritis
									</Cta>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id='StayInformed' className='bg-coral text-center'>
			<div className='container container--narrow'>
				<div className='copy-block'>
					<IconMailLarge />
					<h2>Stay Informed</h2>
					<p>
						Sign up for the latest LUPKYNIS news and get more information
						about Aurinia Alliance<sup>&reg;</sup>, our personalized support
						program for people who are taking LUPKYNIS.{' '}
						<strong>
							You can also request to be contacted by an Aurinia Alliance
							Nurse Case Manager to help you enroll in the program and
							connect you with other services like our copay program for
							eligible commercial patients.
						</strong>
					</p>
					<div>
						<Link to='/registration' className='button'>
							Sign up
						</Link>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default Index;
